import { DateTime } from "../../utils/luxon.js";
import type { ProductForCustomer } from "../Product.js";
import {
	maxOrderHour,
	maxOrderMinute,
	minOrderHour,
	minOrderMinute,
	orderCutoffHours,
	orderTimeStepInSeconds,
} from "../orderTimes.js";

export function getNearestDeliveryDateForProduct(
	product: ProductForCustomer,
	requestDate = DateTime.now(),
): Date | undefined {
	let dateOfProduction = requestDate.plus({ day: product.daysForProduction });

	/*
	 * If date of production is before the minimal order hour and minute, move it up to the minimal order hour and minute.
	 */
	if (
		dateOfProduction.get("hour") < minOrderHour ||
		(dateOfProduction.get("hour") === minOrderHour && dateOfProduction.get("minute") < minOrderMinute)
	) {
		dateOfProduction = dateOfProduction.set({ hour: minOrderHour, minute: minOrderMinute, second: 0, millisecond: 0 });
	}

	/*
	 * The starting nearest delivery date is the date of production rounded up to the nearest order time step.
	 * For example:
	 * - The order time step is 15 minutes
	 * - The date of production is 1. 1. 2024 12:34:56
	 * The nearest delivery date is 1. 1. 2024 12:45:00
	 */
	let nearestDeliveryDate = DateTime.fromSeconds(
		Math.min(
			Math.ceil(dateOfProduction.toSeconds() / orderTimeStepInSeconds) * orderTimeStepInSeconds,
			dateOfProduction.endOf("day").toSeconds(),
		),
	);

	/*
	 * If the order is placed before the cutoff hour, the order can be delivered on the minimal order hour and minute.
	 * This only applies if the product takes atleast one day to produce.
	 * For example:
	 * - The cutoff hour is 12:00
	 * - The minimal order time is 10:00
	 * When the order is placed at 11:59, the order can be delivered at 10:00.
	 * When the order is placed at 12:00, the order can be delivered after the current hour.
	 */
	if (requestDate.get("hour") < orderCutoffHours && product.daysForProduction >= 1) {
		nearestDeliveryDate = nearestDeliveryDate.set({
			hour: minOrderHour,
			minute: minOrderMinute,
			second: 0,
			millisecond: 0,
		});
	}

	/*
	 * If the product takes exactly one day to produce and the order is placed after the cutoff hour, the order can be delivered the next day at the minimal order hour and minute.
	 */
	if (product.daysForProduction === 1 && requestDate.get("hour") >= orderCutoffHours) {
		nearestDeliveryDate = nearestDeliveryDate.plus({ day: 1 }).set({
			hour: minOrderHour,
			minute: minOrderMinute,
			second: 0,
			millisecond: 0,
		});
	}

	/*
	 * If the currently calculated nearest delivery date exceeds the maximum order hour and minute, the order can be delivered the next day at the minimal order hour and minute.
	 */
	if (
		nearestDeliveryDate.get("hour") > maxOrderHour ||
		(nearestDeliveryDate.get("hour") === maxOrderHour && nearestDeliveryDate.get("minute") > maxOrderMinute)
	) {
		nearestDeliveryDate = nearestDeliveryDate.plus({ day: 1 }).set({
			hour: minOrderHour,
			minute: minOrderMinute,
			second: 0,
			millisecond: 0,
		});
	}

	if (product.enablePermittedPickUpDates) {
		const permittedDateTimes = product.permittedPickUpDates.map((date) => DateTime.fromISO(date));
		const [nearestPermittedDateTime] = permittedDateTimes
			.filter((date) => date.endOf("day") > nearestDeliveryDate)
			.toSorted((first, second) => (first < second ? -1 : 1));

		if (!nearestPermittedDateTime) {
			return undefined;
		}

		nearestDeliveryDate = DateTime.max(
			nearestDeliveryDate,
			nearestPermittedDateTime.set({
				hour: nearestDeliveryDate.get("hour"),
				minute: nearestDeliveryDate.get("minute"),
				second: 0,
				millisecond: 0,
			}),
		);

		const maxPermittedDateTime = DateTime.max(...permittedDateTimes);
		if (nearestDeliveryDate.startOf("day") > maxPermittedDateTime.endOf("day")) {
			return undefined;
		}
	}

	return nearestDeliveryDate.toJSDate();
}

export function getNearestDeliveryDate(products: ProductForCustomer[], withDate?: DateTime): Date | undefined {
	let largest: Date | undefined;
	for (const product of products) {
		const date = getNearestDeliveryDateForProduct(product, withDate);
		if (!date) {
			return undefined;
		}
		if (!largest || date > largest) {
			largest = date;
		}
	}

	if (!largest) {
		return undefined;
	}

	const hasPermittedPickUpDates = products.some(({ enablePermittedPickUpDates }) => enablePermittedPickUpDates);
	if (!hasPermittedPickUpDates) {
		return largest;
	}

	const allPermittedPickUpDates = products.flatMap(({ permittedPickUpDates }) => permittedPickUpDates);
	const commonPickupDate = allPermittedPickUpDates
		.toSorted((first, second) => new Date(first).getTime() - new Date(second).getTime())
		.find((permittedDate) => {
			const date = DateTime.fromISO(permittedDate);
			if (date.startOf("day") < DateTime.fromJSDate(largest).startOf("day")) {
				return false;
			}
			return products.every(({ enablePermittedPickUpDates, permittedPickUpDates }) => {
				if (!enablePermittedPickUpDates) {
					return true;
				}
				return permittedPickUpDates.includes(permittedDate);
			});
		});
	if (!commonPickupDate) {
		return undefined;
	}
	if (DateTime.fromISO(commonPickupDate).toISODate() === DateTime.fromJSDate(largest).toISODate()) {
		return largest;
	}
	return DateTime.fromISO(commonPickupDate)
		.set({
			hour: minOrderHour,
			minute: minOrderMinute,
			second: 0,
			millisecond: 0,
		})
		.toJSDate();
}

export function getNearestDeliveryDateForVouchersOnly(): Date {
	let nearestDate = DateTime.now();

	if (
		nearestDate.get("hour") < minOrderHour ||
		(nearestDate.get("hour") === minOrderHour && nearestDate.get("minute") < minOrderMinute)
	) {
		nearestDate.set({ hour: minOrderHour, minute: minOrderMinute, second: 0, millisecond: 0 });
	}

	if (
		nearestDate.get("hour") > maxOrderHour ||
		(nearestDate.get("hour") === maxOrderHour && nearestDate.get("minute") > maxOrderMinute)
	) {
		nearestDate = nearestDate.plus({ day: 1 }).set({
			hour: minOrderHour,
			minute: minOrderMinute,
			second: 0,
			millisecond: 0,
		});
	}

	return nearestDate.toJSDate();
}
